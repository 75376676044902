import React, { useEffect, useState } from "react"
import ServiceSidebar from "./ServiceSidebar"
import details1 from "../../assets/images/services/services-details1.jpg"
import project2 from "../../assets/images/projects/project2.jpg"
import charts from "../../assets/images/services/charts.jpg";

import { serviceDetails } from "./serviceDetails.json"

const ServiceDetailsContent = ({ service }) => {
  const [services, setServices] = useState(service)

  useEffect(() => {
    setServices(service)
  }, [service])

  const onClickTab = item => {
    setServices(item)
  }
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          {serviceDetails?.map((item, i) => {
            return (
              item?.serviceName == services && (
                <div className="col-lg-8 col-md-12" key={i}>
                  <div className="services-details-image">
                    <img src={details1} alt="about" />
                  </div>

                  <div className="services-details-desc">
                    <span className="sub-title">{item?.serviceName}</span>
                    <h3>About this Services</h3>
                    <p>{item?.serviceDescription}</p>

                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-6">
                        <div className="image">
                          <img src={project2} alt="about" />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="content">
                          <h3>Important Facts</h3>
                          <ul>
                            {item?.keywords?.map((item, i) => {
                              return <li key={i}>{item.keyword}</li>
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <p>{item?.serviceDetail}</p>
                    <h3>Application Areas</h3>

                    <div className="row">
                      {item?.applicationAreas?.map((list, i) => {
                        return (
                          <div className="col-lg-4 col-sm-6 col-md-6" key={i}>
                            <div className="single-industries-serve-box">
                              <div className="icon">
                                <i className="flaticon-factory"></i>
                              </div>
                              {list?.name}
                            </div>
                          </div>
                        )
                      })}
                    </div>

                    <h3>Technologies That We Use</h3>
                    <ul className="technologies-features">
                      {item?.technologies?.map(item => {
                        return (
                          <li key={i}>
                            <span>{item?.name}</span>
                          </li>
                        )
                      })}
                    </ul>
                    <div className="charts-image">
                      <img src={charts} alt="about" />
                    </div>
                  </div>
                </div>
              )
            )
          })}

          <div className="col-lg-4 col-md-12">
            <ServiceSidebar onClickTab={onClickTab} activeService={services} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceDetailsContent
