import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContent from '../components/ServiceDetails/ServiceDetailsContent'
import RelatedServices from '../components/ServiceDetails/RelatedServices'

const Details = ({ location }) => {
    console.log(location, 'fromFeed')
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Service Details"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Service Details"
            />
            <ServiceDetailsContent service={location?.state?.service} />
            {/* <RelatedServices /> */}
            <Footer />
        </Layout>
    );
}

export default Details