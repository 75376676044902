import React from "react"
import { Link } from "gatsby"
import ServicesOne from "../Services/ServicesOne"

const ServiceSidebar = ({ onClickTab, activeService }) => {
  return (
    <div className="services-details-info">
      <ul className="services-list">
        <li>
          <Link
            to="/service-details"
            onClick={e => {
              onClickTab("Full-Stack Development")
              e.preventDefault()
            }}
            className={
              activeService === "Full-Stack Development" ? "active" : ""
            }
          >
            {/* Data Analytics */}
            Full-Stack Development
          </Link>
        </li>
        <li>
          <Link
            to="/service-details"
            onClick={e => {
              onClickTab("Mobile Application")
              e.preventDefault()
            }}
            className={activeService === "Mobile Application" ? "active" : ""}
          >
            {/* Data Science */}
            Mobile Application
          </Link>
        </li>
        <li>
          <Link
            to="/service-details"
            onClick={e => {
              onClickTab("UX/UI Design")
              e.preventDefault()
            }}
            className={activeService === "UX/UI Design" ? "active" : ""}
          >
            UX/UI Design
          </Link>
        </li>
        <li>
          <Link
            to="/service-details"
            onClick={e => {
              onClickTab("SEO")
              e.preventDefault()
            }}
            className={activeService === "SEO" ? "active" : ""}
          >
            {/* Artificial Intelligence */}
            SEO
          </Link>
        </li>
        <li>
          <Link
            to="/service-details"
            onClick={e => {
              onClickTab("Digital Marketing")
              e.preventDefault()
            }}
            className={activeService === "Digital Marketing" ? "active" : ""}
          >
            {/* Artificial Intelligence */}
            Digital Marketing
          </Link>
        </li>
        <li>
          <Link
            to="/service-details"
            onClick={e => {
              onClickTab("Web Developement")
              e.preventDefault()
            }}
            className={activeService === "Web Developement" ? "active" : ""}
          >
            {/* Artificial Intelligence */}
            Web Developement
          </Link>
        </li>
        <li>
          <Link
            to="/service-details"
            onClick={e => {
              onClickTab("Automated Testing")
              e.preventDefault()
            }}
            className={activeService === "Automated Testing" ? "active" : ""}
          >
            {/* Data Visualization */}
            Automated Testing
          </Link>
        </li>
        <li>
          <Link
            to="/service-details"
            onClick={e => {
              onClickTab("Startup Applications")
              e.preventDefault()
            }}
            className={activeService === "Startup Applications" ? "active" : ""}
          >
            {/* Data Visualization */}
            Startup Applications
          </Link>
        </li>
      </ul>

      <div className="download-file">
        <h3>Brochures</h3>

        <ul>
          <li>
            <Link to="#">
              PDF Download <i className="bx bxs-file-pdf"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              Services Details.txt <i className="bx bxs-file-txt"></i>
            </Link>
          </li>
        </ul>
      </div>

      <div className="services-contact-info">
        <h3>Contact Info</h3>

        <ul>
          <li>
            <div className="icon">
              <i className="bx bx-user-pin"></i>
            </div>
            <span>Phone:</span>
            <a href="tel:+21453545413">+92 355 4206240</a>
          </li>
          <li>
            <div className="icon">
              <i className="bx bx-map"></i>
            </div>
            <span>Location:</span>
            {/* New York, USA */}
            Ali Business Complex, Block 8, Naveed Shaheed Road Zulfiqarabad
            Jutial Gilgit
          </li>
          <li>
            <div className="icon">
              <i className="bx bx-envelope"></i>
            </div>
            <span>Email:</span>
            {/* <a href="mailto:hello@tarn.com">hello@tarn.com</a> */}
            <a href="info@northcodex.com">info@northcodex.com</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ServiceSidebar
