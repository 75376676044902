import React from "react"
import { Link } from "gatsby"

const ServicesOne = () => {
  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-rocket"></i>
              </div>
              <h3>
                <Link to="/service-details">Startup Applications</Link>
              </h3>
              <p>
                We will help you grow your Startup by building next generation
                applications
              </p>

              <Link
                className="view-details-btn"
                to="/service-details"
                state={{ service: "Startup Applications" }}
              >
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>

              <h3>
                <Link to="/service-details">
                  {/* SaaS Solutions */}
                  UX/UI Design
                </Link>
              </h3>

              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p> */}
              <p>
                NC North Codex mainly working on design and development,
                Graphic, Mobile app development digital marketing and many more.
                We work closely with enterprise level customer to create high
                performance secure customer websites with a focus on unique,
                profession websites design.
              </p>

              <Link
                className="view-details-btn"
                to="/service-details"
                state={{ service: "UX/UI Design" }}
              >
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-money"></i>
              </div>

              <h3>
                <Link to="/service-details">
                  {/* eCommerce Platforms */}
                  Automated Testing
                </Link>
              </h3>

              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p> */}
              <p>
                This application would help you to find fact and figures weather
                you ‘re browsing as per required. The world’s leading mobile and
                web app developers rely on Testlio’s manual and automation best
                practices in software testing to manage test runs, review
                builds, and provide results validation.
              </p>

              <Link
                className="view-details-btn"
                to="/service-details"
                state={{ service: "Automated Testing" }}
              >
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-segmentation"></i>
              </div>

              <h3>
                <Link to="/service-details">
                  {/* Research */}
                  Full-Stack Development
                </Link>
              </h3>

              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p> */}
              <p>
                We are offering to client's to involve web developer front and
                back end of websites application databases building user-facing
                websites to during the planning phase of projects. These
                professionals usually work with a product through its initial{" "}
              </p>
              <Link
                className="view-details-btn"
                to="/service-details"
                state={{ service: "Full-Stack Development" }}
              >
                View Details
              </Link>
            </div>
          </div>

          {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Analytics
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>

                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div> */}

          {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Technology
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>

                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div> */}
          {/* </div> */}
        </div>
      </div>
    </section>
  )
}

export default ServicesOne
